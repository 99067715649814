import React, { FunctionComponent } from "react"
import Game from "../components/Game";

const Home: FunctionComponent = () => (
  <>
    <Game/>
  </>
);

export default Home;
